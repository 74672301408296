<template>
  <div class="flex" style="height: 100vh">
    <div
      class="hidden md:flex md:items-center md:justify-center md:w-1/3 bg-white"
      :style="`height: 100vh; background-color: #6a71ec; background-size: cover; background-position: center;`"
    >
      <img
        class="mx-auto"
        src="/static/logo_darkmode.png"
        alt="jevi-white-logo"
        style="z-index: 50"
      />
    </div>
    <div
      class="block md:flex md:flex-col w-full md:w-2/3 center justify-center bg-gray-200 dark:bg-jevi-dark overflow-y-auto"
    >
      <div class="md:hidden">
        <router-link to="/">
          <div class="w-2/3 mb-6 mx-auto">
            <img v-if="theme === 'dark'" src="/static/logo_darkmode.png" />
              <img v-else src="/static/logo_lightmode.png" />
          </div>
        </router-link>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    
    }
  },
  props: {
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    }
  },
}
</script>

